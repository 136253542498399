
import { defineComponent, PropType } from "vue";
import CardComponent from "@/components/card/CardComponent.vue";
import LoadingIndicator from "@/components/spinners/LoadingIndicator.vue";
import { User } from "@/store/modules/AuthModule";

interface Payment {
  id?: number;
  order_id?: number;
  order_reference?: string;
  amount?: number;
  type?: string;
  created_at?: string;
  user_name?: string;
  user?: User;
}

export default defineComponent({
  name: "VoucherPaymentHistoryWidget",
  components: {
    CardComponent,
    LoadingIndicator,
  },
  props: {
    payments: {
      type: Object as PropType<Payment[]>,
      required: true,
    },
  },
});
