<template>
  <div v-if="state.response" class="containter">
    <div class="row">
      <div class="col">
        <update-voucher-widget
          :voucher="state.response.voucher"
          @updated="() => voucherUpdated()"
        />
      </div>
      <div class="col">
        <voucher-payment-history-widget :payments="state.response.payments" />
      </div>
    </div>
  </div>
  <div></div>
</template>

<script lang="ts">
import ApiService from "@/core/services/ApiService";
import { defineComponent, reactive } from "vue";
import Notify from "@/modules/common/helpers/Notify";
import { CustomerResponse } from "@/modules/customers/interfaces/CustomerResponse.interface";
import { useRoute } from "vue-router";
import VoucherPaymentHistoryWidget from "@/modules/vouchers/widgets/VoucherPaymentHistoryWidget.vue";
import UpdateVoucherWidget from "@/modules/vouchers/widgets/UpdateVoucherWidget.vue";

interface ComponentData {
  response?: CustomerResponse | null;
  submitButtonLoading: boolean;
  isEditCustomer: boolean;
}

export default defineComponent({
  name: "VouchersViewPage",
  components: {
    UpdateVoucherWidget,
    VoucherPaymentHistoryWidget,
  },

  setup() {
    const route = useRoute();
    const state = reactive<ComponentData>({
      response: null,
      submitButtonLoading: false,
      isEditCustomer: false,
    });

    const voucherUpdated = (): void => {
      state.response = null;
      getVoucher();
    };

    const getVoucher = (): void => {
      ApiService.get(`voucher/` + String(route.params.id))
        .then(({ data }) => {
          state.response = data;
        })
        .catch((error) => Notify.apiError(error))
        .finally();
    };
    getVoucher();

    return {
      state,
      voucherUpdated,
      getVoucher,
    };
  },
});
</script>

<style scoped></style>
