<template>
  <card-component>
    <template #header>Voucher payment history</template>
    <template #default>
      <el-table
        v-if="payments != null"
        :data="payments"
        class="table table-sm table-borderless"
        size="mini"
      >
        <el-table-column prop="amount" label="Amount" width="120">
          <template #default="{ row }">
            <span
              :class="{
                'text-danger': row.amount <= 0,
              }"
              >{{ $filters.currencyEUR(row.amount) }}</span
            >
          </template>
        </el-table-column>
        <el-table-column prop="type" label="Type" />
        <el-table-column prop="order_id" label="Order ID" width="120">
          <template #default="{ row }">
            <span v-if="row.order_id !== 0">
              <a :href="'/orders/' + row.order_id" target="_blank">
                {{ row.order_id }}
              </a>
            </span>
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column prop="order_reference" label="Order reference">
          <template #default="{ row }">
            <span v-if="row.order_reference != null">
              <a :href="'/orders/' + row.order_reference" target="_blank">
                {{ row.order_reference }}
              </a>
            </span>
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column prop="created_at" label="Date" />
        <el-table-column prop="user_name" label="User">
          <template #default="{ row }">
            <span v-if="row.user != null">
              {{ row.user.name }}
            </span>
          </template>
        </el-table-column>
      </el-table>
      <div v-else class="text-center">
        <loading-indicator />
      </div>
    </template>
  </card-component>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import CardComponent from "@/components/card/CardComponent.vue";
import LoadingIndicator from "@/components/spinners/LoadingIndicator.vue";
import { User } from "@/store/modules/AuthModule";

interface Payment {
  id?: number;
  order_id?: number;
  order_reference?: string;
  amount?: number;
  type?: string;
  created_at?: string;
  user_name?: string;
  user?: User;
}

export default defineComponent({
  name: "VoucherPaymentHistoryWidget",
  components: {
    CardComponent,
    LoadingIndicator,
  },
  props: {
    payments: {
      type: Object as PropType<Payment[]>,
      required: true,
    },
  },
});
</script>

<style scoped></style>
