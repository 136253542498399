<template>
  <card-component>
    <template #header>Update voucher</template>
    <template #default>
      <div v-if="voucher != null">
        <form autocomplete="off" @submit="updateVoucher">
          <div class="row m-2">
            <div class="col-3 m-auto">
              <label for="title" class="form-label float-end">Title</label>
            </div>
            <div class="col-9">
              <Field
                id="title"
                name="title"
                type="text"
                class="form-control form-control-solid"
                placeholder="Title"
                required
                autocomplete="nope"
              />
              <div class="fv-plugins-message-container">
                <div class="fv-help-block">
                  <ErrorMessage name="translations.0.title" />
                </div>
              </div>
            </div>
          </div>

          <div class="row m-2">
            <div class="col-3 m-auto">
              <label for="description" class="form-label float-end">
                Description
              </label>
            </div>
            <div class="col-9">
              <Field
                id="description"
                name="description"
                type="text"
                class="form-control form-control-solid"
                placeholder="Description"
                required
                autocomplete="nope"
                model-value="description"
              />
              <div class="fv-plugins-message-container">
                <div class="fv-help-block">
                  <ErrorMessage name="translations.0.description" />
                </div>
              </div>
            </div>
          </div>

          <div class="row m-2">
            <div class="col-3 m-auto">
              <label for="description" class="form-label float-end">
                Code
              </label>
            </div>
            <div class="col-9">
              <span class="badge badge-success badge_code">
                {{ voucher.code }}
              </span>
            </div>
          </div>

          <div class="row m-2">
            <div class="col-3 my-auto">
              <label for="voucher_value" class="form-label float-end"> Value </label>
            </div>
            <div class="col-3">
              <Field
                id="voucher_value"
                name="voucher_value"
                type="text"
                class="form-control form-control-solid"
                placeholder="voucher_value"
                disabled
                autocomplete="nope"
                style="cursor: not-allowed"
              />
            </div>
            <div class="col-3 my-auto">
              <div class="row voucher_balance_values">
                Cash: {{ voucher.main_balance }}
              </div>
              <div class="row voucher_balance_values">
                Promo: {{ voucher.promotion_balance }}
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-3 my-auto">
              <label for="amount" class="form-label float-end">
                Adjust balance
              </label>
            </div>
            <div class="col-2">
              <Field
                id="adjust_balance"
                name="adjust_balance"
                type="text"
                class="form-control form-control-solid"
                placeholder="Amount"
                autocomplete="nope"
              />
              <div class="fv-plugins-message-container">
                <div class="fv-help-block">
                  <ErrorMessage name="adjust_balance" />
                </div>
              </div>
            </div>
            <div class="col-4">
              <select
                id="items_bulk_actions"
                v-model="state.adjustOperation"
                class="form-control form-control-solid form-select"
                name="adjust_operation"
              >
                <option value="increase">Increase</option>
                <option value="decrease">Decrease</option>
                <option value="book_to_order">Book to Order</option>
                <option value="book_from_order">Book from Order</option>
              </select>
            </div>
            <div class="col-3">
              <Field
                v-if="
                  state.adjustOperation === 'book_to_order' ||
                  state.adjustOperation === 'book_from_order'
                "
                id="amount"
                name="adjust_order_reference"
                type="text"
                class="form-control form-control-solid"
                placeholder="Order reference"
                autocomplete="nope"
              />
              <div class="fv-plugins-message-container">
                <div class="fv-help-block">
                  <ErrorMessage name="adjust_order_reference" />
                </div>
              </div>
            </div>
          </div>

          <div class="row mb-2">
            <div class="col-4 offset-5">
              <div
                class="form-check form-check-custom form-check-sm justify-content-center"
              >
                <label>
                  <input
                    id="adjust_is_promotion"
                    v-model="state.adjustIsPromotion"
                    name="adjust_is_promotion"
                    class="form-check-input"
                    type="checkbox"
                    autocomplete="off"
                  />
                  Promotion Bonus
                </label>
              </div>
            </div>
          </div>

          <div class="row m-2">
            <div class="col-3 pt-2">
              <label for="is_active" class="form-label float-end">
                Is active
              </label>
            </div>
            <div class="col-3 pt-2">
              <div class="form-check form-check-custom form-check-sm">
                <input
                  id="is_active"
                  v-model="state.isActive"
                  name="is_active"
                  class="form-check-input"
                  type="checkbox"
                  autocomplete="off"
                />
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col text-center mb-5">
              <form-submit-button :loading="state.submitButtonLoading">
                Update Voucher
              </form-submit-button>
            </div>
          </div>
        </form>
      </div>
      <div v-else class="text-center">
        <loading-indicator />
      </div>
    </template>
  </card-component>
</template>

<script lang="ts">
import { defineComponent, onMounted, PropType, reactive } from "vue";
import CardComponent from "@/components/card/CardComponent.vue";
import LoadingIndicator from "@/components/spinners/LoadingIndicator.vue";
import { ErrorMessage, Field, useForm } from "vee-validate";
import ApiService from "@/core/services/ApiService";
import { ElMessage } from "element-plus";
import Notify from "@/modules/common/helpers/Notify";
import FormSubmitButton from "@/components/forms/buttons/FormSubmitButton.vue";

interface Voucher {
  id: number;
  code: string;
  amount?: number;
  title?: number;
  description?: number;
  is_active: boolean;
  main_balance?: number;
  promotion_balance?: number;
}

interface ComponentData {
  submitButtonLoading: boolean;
  isActive: boolean;
  adjustIsPromotion: boolean;
  languageId: number;
  adjustOperation: string;
}

export default defineComponent({
  name: "UpdateVoucherWidget",
  components: {
    CardComponent,
    LoadingIndicator,
    Field,
    FormSubmitButton,
    ErrorMessage,
  },
  props: {
    voucher: {
      type: Object as PropType<Voucher>,
      required: true,
    },
  },

  emits: ["updated"],
  setup(props, { emit }) {
    const state = reactive<ComponentData>({
      submitButtonLoading: false,
      isActive: props.voucher.is_active,
      adjustIsPromotion: false,
      languageId: 1,
      adjustOperation: "increase",
    });

    const { setValues, handleSubmit, setFieldError } = useForm();

    onMounted(() => {
      setValues(props.voucher);
    });

    const updateVoucher = handleSubmit((values) => {
      const requestBody = {
        ...values,
        is_active: state.isActive,
        translations: [
          {
            language_id: state.languageId,
            title: values.title,
            description: values.description,
          },
        ],
        adjust_is_promotion: state.adjustIsPromotion,
        adjust_operation: state.adjustOperation,
      };

      state.submitButtonLoading = true;
      ApiService.put(`voucher/${props.voucher.id}`, requestBody)
        .then(() => {
          ElMessage.success("Voucher updated successfully");
          emit("updated");
        })
        .catch((error) => {
          Notify.apiError(error, setFieldError);
        })
        .finally(() => {
          state.submitButtonLoading = false;
        });
    });

    return {
      state,
      updateVoucher,
      props,
    };
  },
});
</script>

<style scoped>
.badge_code {
  font-size: 1.2rem;
  line-height: 1.4rem;
}
.voucher_balance_values {
  font-size: 1.1rem;
}
</style>
