
import { defineComponent, onMounted, PropType, reactive } from "vue";
import CardComponent from "@/components/card/CardComponent.vue";
import LoadingIndicator from "@/components/spinners/LoadingIndicator.vue";
import { ErrorMessage, Field, useForm } from "vee-validate";
import ApiService from "@/core/services/ApiService";
import { ElMessage } from "element-plus";
import Notify from "@/modules/common/helpers/Notify";
import FormSubmitButton from "@/components/forms/buttons/FormSubmitButton.vue";

interface Voucher {
  id: number;
  code: string;
  amount?: number;
  title?: number;
  description?: number;
  is_active: boolean;
  main_balance?: number;
  promotion_balance?: number;
}

interface ComponentData {
  submitButtonLoading: boolean;
  isActive: boolean;
  adjustIsPromotion: boolean;
  languageId: number;
  adjustOperation: string;
}

export default defineComponent({
  name: "UpdateVoucherWidget",
  components: {
    CardComponent,
    LoadingIndicator,
    Field,
    FormSubmitButton,
    ErrorMessage,
  },
  props: {
    voucher: {
      type: Object as PropType<Voucher>,
      required: true,
    },
  },

  emits: ["updated"],
  setup(props, { emit }) {
    const state = reactive<ComponentData>({
      submitButtonLoading: false,
      isActive: props.voucher.is_active,
      adjustIsPromotion: false,
      languageId: 1,
      adjustOperation: "increase",
    });

    const { setValues, handleSubmit, setFieldError } = useForm();

    onMounted(() => {
      setValues(props.voucher);
    });

    const updateVoucher = handleSubmit((values) => {
      const requestBody = {
        ...values,
        is_active: state.isActive,
        translations: [
          {
            language_id: state.languageId,
            title: values.title,
            description: values.description,
          },
        ],
        adjust_is_promotion: state.adjustIsPromotion,
        adjust_operation: state.adjustOperation,
      };

      state.submitButtonLoading = true;
      ApiService.put(`voucher/${props.voucher.id}`, requestBody)
        .then(() => {
          ElMessage.success("Voucher updated successfully");
          emit("updated");
        })
        .catch((error) => {
          Notify.apiError(error, setFieldError);
        })
        .finally(() => {
          state.submitButtonLoading = false;
        });
    });

    return {
      state,
      updateVoucher,
      props,
    };
  },
});
